import React, { useState, useEffect, useContext } from 'react';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PropTypes } from 'prop-types';
import CheckOutForm from './checkOutForm';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import SubscribeCheckOutForm from './SubscribeCheckOutForm';

const SubsPayment = ({
  returnPopUp, handleAction, handleFormModal, clientData, amountDisplay, 
}) => {
  const { userData, setUserData } = useContext(AuthDataContext);
  const [openPaymentForm, setOpenPaymentForm] = useState(true);

  useEffect(() => {
    console.log('userData?.userDetails?.payment===>', userData?.userDetails?.payment);
    // if (userData?.userDetails?.payment === 'pending') {
    //   setOpenPaymentForm(true);
    // }
    
    setOpenPaymentForm(true);
  }, []);
  // const stripePromise = loadStripe('pk_test_51PqB9VP0R6JGt9iCUPtgdIPoywkXaeRWM1gGFqABDlNCSErDPrAJhETEKwA2eSWrTzqoNUYBv9JlOQU36iixKZ4o005rIxFRwl');
  // const stripePromise = loadStripe('pk_test_51Pq4ToJYCAG32lQBih5RUhcesiLBizqXO47Vfamcv2cIyMybcSK93Aaaf718RY73pD4TcmyAS1xSyebsVkx7wDnY00q7jyEpuW');
  
  // test mode key=
  // const stripePromise = loadStripe('pk_test_51Pq4ToJYCAG32lQBih5RUhcesiLBizqXO47Vfamcv2cIyMybcSK93Aaaf718RY73pD4TcmyAS1xSyebsVkx7wDnY00q7jyEpuW');
   
  // live code 
  const stripePromise = loadStripe('pk_live_51Pq4ToJYCAG32lQBfxCl6biCxDeGs4Y5R6Nn7JUJRIiZNoJkqlnx8mbzMtrltpT5Ihxj2mveHstXZAV2U0waTqB300oUZbbGRt');
  return (
    <Elements stripe={stripePromise}>
      {/* <CheckOutForm
        openPaymentForm={openPaymentForm}
        payloadData={userData?.userDetails}
        setOpenPaymentForm={setOpenPaymentForm}
      /> */}
      <SubscribeCheckOutForm
        returnPopUp={returnPopUp}
        handleAction={handleAction}
        clientData={clientData}
        handleFormModal={handleFormModal}
        amountDisplay={amountDisplay}
      />
    </Elements>
  );
};
export default SubsPayment;

SubsPayment.propTypes = {
  returnPopUp: PropTypes.string.isRequired,
  clientData: PropTypes.objectOf.isRequired,
  amountDisplay: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  // setOpenPaymentForm: PropTypes.func.isRequired,
};
